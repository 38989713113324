import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import IconBookOpen from 'assets/Icons/IconBookOpen';
import IconInviteNewUser from 'assets/Icons/IconInviteUser';
import IconMessageNotification from 'assets/Icons/IconMessageNotification';
import { InviteNewUser } from 'features/InviteNewUser/InviteNewUser';
import { UserSettings } from 'features/UserSettings/userSettings';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NavigationTabs from 'shared/components/NavigationTabs/NavigationTabs';

export const Header: React.FC = () => {
  const [userSettingsDialogOpen, setUserSettingsDialogOpen] = useState(false);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getProfileInitials = () => {
    const userData = localStorage.getItem('userData');
    const tokenData = JSON.parse(userData as string);

    if (tokenData && tokenData['FullName']) {
      const fullName = tokenData['FullName'];
      const names = fullName.split(' ');
      if (names.length >= 2) {
        return names[0][0] + names[names.length - 1][0];
      } else if (names.length === 1) {
        return names[0][0];
      }
    }
    return '';
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserSettingsClick = () => {
    setUserSettingsDialogOpen(true);
    setAnchorEl(null);
  };

  const handleUserSettingsClose = () => {
    setUserSettingsDialogOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    navigate(`/login`);
  };

  const handleInviteUserClick = () => {
    setInviteUserDialogOpen(true);
  };
  const handleInviteUserDialogClose = () => {
    setInviteUserDialogOpen(false);
  };

  const handleBreeamManualClick = () => {
    window.open('https://www.sgbc.se/app/uploads/2023/05/Manual_BREEAM-SE_SV_v6.0.2.pdf', '_blank');
  };


  return (
    <>
      <AppBar sx={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: '1px solid #D0D5DD' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '120px' }}>
          <Box display='flex' flexDirection='row'>
            <Box sx={{ paddingTop: '16px' }}>
              <NavigationTabs />
            </Box>
          </Box>
          <Box display='flex' justifyContent='row' alignItems='center' gap='12px'>
            {/* <IconButton sx={{ padding: 0 }}>
              <IconMessageNotification />
            </IconButton> */}
            <IconButton onClick={handleBreeamManualClick} sx={{ padding: 0 }}>
              <IconBookOpen />
            </IconButton>
            <IconButton onClick={handleInviteUserClick} sx={{ padding: 0 }}>
              <IconInviteNewUser />
            </IconButton>
            <Avatar
              onClick={handleClick}
              sx={{
                width: '30px',
                height: '30px',
                bgcolor: 'white',
                color: '#667085',
                border: '1px solid #D0D5DD',
                cursor: 'pointer'
              }}
            >
              <Typography variant='titleM' component={'span'}>
                {getProfileInitials()}
              </Typography>
            </Avatar>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleUserSettingsClick}>{t('Project.UserSettings.Settings')}</MenuItem>
              <MenuItem onClick={logout}>{t('Project.UserSettings.LogOut')}</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {userSettingsDialogOpen && <UserSettings open={userSettingsDialogOpen} onClose={handleUserSettingsClose} />}
      {inviteUserDialogOpen && <InviteNewUser open={inviteUserDialogOpen} onClose={handleInviteUserDialogClose} />}
    </>
  );
};
