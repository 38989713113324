import { Box } from '@mui/material';
import { memo } from 'react';
import { ErrorOutlineOutlined } from '@mui/icons-material';

const UpdatingInfoIcon = memo(() => {
  const circleStyle = {
    content: '""',
    position: 'absolute',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return (
    <Box p={1.5} display='flex' justifyContent='center' alignItems='center' position='relative' borderRadius='50%' bgcolor='#FEF0C7'>
      <Box
        sx={{
          '&::before': {
            ...circleStyle,
            borderColor: '#EAECF055',
            width: 'calc(100% + 320px)',
            height: 'calc(100% + 320px)'
          }
        }}
      />
      <Box
        sx={{
          '&::before': {
            ...circleStyle,
            borderColor: '#EAECF0CC',
            width: 'calc(100% + 192px)',
            height: 'calc(100% + 192px)'
          },
          '&::after': {
            ...circleStyle,
            borderColor: '#EAECF066',
            width: 'calc(100% + 256px)',
            height: 'calc(100% + 256px)'
          }
        }}
      />
      <Box
        sx={{
          '&::before': {
            ...circleStyle,
            borderColor: '#EAECF0',
            width: 'calc(100% + 64px)',
            height: 'calc(100% + 64px)'
          },
          '&::after': {
            ...circleStyle,
            borderColor: '#EAECF0CC',
            width: 'calc(100% + 128px)',
            height: 'calc(100% + 128px)'
          }
        }}
      />
      <ErrorOutlineOutlined fontSize='large' sx={{ color: '#DC6803' }} />
    </Box>
  );
});

export default UpdatingInfoIcon;
