export default function IconInviteNewUser() {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.9998 17.9167H11.2498C10.0869 17.9167 9.50538 17.9167 9.03222 18.0602C7.96688 18.3834 7.1332 19.217 6.81004 20.2824C6.6665 20.7555 6.6665 21.337 6.6665 22.5M20.8332 22.5V17.5M18.3332 20H23.3332M17.0832 11.25C17.0832 13.3211 15.4042 15 13.3332 15C11.2621 15 9.58317 13.3211 9.58317 11.25C9.58317 9.17893 11.2621 7.5 13.3332 7.5C15.4042 7.5 17.0832 9.17893 17.0832 11.25Z'
        stroke='#667085'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
