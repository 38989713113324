import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, Dialog, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSnackbar from 'shared/components/CustomSnackbar';
import useGetProjects from 'shared/hooks/useGetProjects';
import { ListItem } from 'shared/interfaces/listItem';
import useInviteNewUser from './hooks/useInviteNewUser';
import { InviteNewUserRequest, Role } from './interfaces/inviteNewUserRequest';

export const InviteNewUser: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const [errors, setErrors] = useState<{ field: string; message: string }[]>([]);
  const emailError = errors.find((error) => error.field === 'Email')?.message;
  const roleError = errors.find((error) => error.field === 'Role')?.message;
  const projectError = errors.find((error) => error.field === 'ProjectId')?.message;
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const { t } = useTranslation();

  const { inviteNewUserMutationAsync } = useInviteNewUser();

  const roleOptions: ListItem[] = [
    { id: 'AdvisoryProfessional', name: t('Project.CreateAccount.AdvisoryProfessional') },
    { id: 'Assessor', name: t('Project.CreateAccount.Assessor') },
    { id: 'Developer', name: t('Project.CreateAccount.Developer')}
  ];

  const formik = useFormik({
    initialValues: {
      email: '',
      projectId: '',
      role: null as Role | null
    } as InviteNewUserRequest,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const result = await inviteNewUserMutationAsync({
          email: values.email,
          projectId: values.projectId,
          role: values.role
        });

        setSnackBarMessage(result.message);

        setTimeout(() => {
          onClose();
        }, 3000);
      } catch (error: any) {
        setErrors(error.errors);
        setIsLoading(false);
      }
    }
  });

  const { data: projectsRes } = useGetProjects();

  const projectOptions = projectsRes.projects.map((project: { name: string; id: string }) => ({
    label: project.name,
    value: project.id
  }));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: '12px', width: '400px', maxWidth: '400px', maxHeight: '440px', boxShadow: 'none' } }}
    >
      <CustomSnackbar openSnackbar={!!snackBarMessage} message={snackBarMessage} isSuccess />
      <Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between' px={3} paddingTop={3} paddingBottom='20px'>
          <Typography sx={{ fontSize: '20px', fontStyle: 'normal', fontWeight: '600', lineHeight: 'normal' }}>
            {t('Project.InviteNewUser.InviteNewUser')}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Grid container direction={'column'} px={3} paddingBottom='24px'>
            <Box>
              <Stack spacing={'20px'}>
                <TextField
                  fullWidth
                  size='small'
                  id='email'
                  name='email'
                  label={t('Project.InviteNewUser.EnterEmailAddress')}
                  value={formik.values.email ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(emailError)}
                  helperText={emailError}
                />
                <Autocomplete
                  id='role'
                  size='small'
                  disablePortal
                  options={roleOptions || []}
                  getOptionLabel={(option: ListItem) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label={t('Project.InviteNewUser.Role')} error={Boolean(roleError)} helperText={roleError} />
                  )}
                  value={roleOptions.find((option) => option.id === formik.values.role) || null}
                  onChange={(_, v) => formik.setFieldValue('role', v?.id)}
                />
                <Autocomplete
                  id='projectId'
                  size='small'
                  disablePortal
                  options={projectOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Project.InviteNewUser.Project')}
                      error={Boolean(projectError)}
                      helperText={projectError}
                    />
                  )}
                  value={projectOptions.find((option) => option.value === formik.values.projectId) || null}
                  onChange={(_, newValue) => formik.setFieldValue('projectId', newValue?.value || null)}
                />
              </Stack>
            </Box>
            <Box paddingTop={4} paddingBottom={3}>
              <Divider variant='fullWidth' />
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='center' gap='12px' px={3}>
              <Button
                onClick={onClose}
                variant='outlined'
                sx={{
                  borderRadius: '8px',
                  borderColor: '#D0D5DD',
                  color: '#344054',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '20px',
                  py: '10px',
                  px: '16px',
                  boxShadow: 'none'
                }}
              >
                {t('Project.InviteNewUser.Cancel')}
              </Button>
              <LoadingButton
                loading={isLoading}
                type='submit'
                variant='contained'
                sx={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '20px',
                  py: '10px',
                  px: '16px',
                  boxShadow: 'none',
                  color: '#2176E',
                }}
              >
                {t('Project.InviteNewUser.SendInvite')}
              </LoadingButton>
            </Box>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
};
