import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      //General
      'Project.General.ErrorFetchingData': 'Error fetching data. Please try again later.',
      'Project.General.Loading': 'Loading...',

      //NavTabs
      'Project.NavTabs.ProjectDetails': 'Project details',
      'Project.NavTabs.ScoreCard': 'Score card',
      'Project.NavTabs.SummaryOfBuildingPerformance': 'Summary of Building Performance',

      //AllProject
      'Project.AllProject.Title': 'All Projects',
      'Project.AllProject.NoProjectsFound': 'No projects found',
      'Project.AllProject. InviteUser': 'Invite user',
      'Project.AllProject.NewProject': 'New project',
      'Project.AllProject. NotCreatedProjet': 'It seems that you have not created a project yet.',
      'Project.AllProject.StartWithThat': "Let's start with that!",

      //ProjectCard
      'Project.ProjectCard.DeleteProject': 'Delete Project',
      'Project.ProjectCard.TargetRating': 'Target rating',
      'Project.ProjectCard.BuildingType': 'Building type',
      'Project.ProjectCard.BuildingStage': 'Building stage',
      'Project.ProjectCard.TargetCO2(A1-A5)': 'Target CO2(A1-A5)',
      'Project.ProjectCard.PreAssessment': 'Pre-assessment',
      'Project.ProjectCard.Edit': 'Edit project',
      'Project.ProjectCard.Pre-assessmentEarlyDesignStage': 'Pre-assessment / Early Design Stage',
      'Project.ProjectCard.DesignStage': 'Design Stage',
      'Project.ProjectCard.Construction': 'Construction',
      'Project.ProjectCard.Options': 'Options',

      //CreateNewProject
      'Project.CreateProject.CreateNewProject': 'Create new project',
      'Project.CreateProject.CreateProject': 'Create project',
      'Project.CreateProject.InitialQuestions': 'Initial questions',
      'Project.CreateProject.Stage': 'What stage are you in?',
      'Project.CreateProject.WoodLegallyHarvested': 'Can the project ensure that the wood products are legally harvested and purchased?',
      'Project.CreateProject.AssetDetails': 'Asset details',
      'Project.CreateProject.BuildingName': 'Building name',
      'Project.CreateProject.BuildingAddress': 'Building address',
      'Project.CreateProject.Region': 'Region',
      'Project.CreateProject.Postcode': 'Postcode',
      'Project.CreateProject.Country': 'Country',
      'Project.CreateProject.AssessmentDetails': 'Assessment details',
      'Project.CreateProject.TargetRating': 'Target rating',
      'Project.CreateProject.EmbodiedCO2Target': 'Embodied CO2 target (A1-A5)',
      'Project.CreateProject.Done': 'Done',
      'Project.CreateProject.Error': 'Error',
      'Project.CreateProject.TargetNotSet': 'Target not set',
      'Project.CreateProject.SetTarget': 'Set target',
      'Project.CreateProject.BuildingTypeMain': 'Building type (main description)',
      'Project.CreateProject.BuildingTypeSub': 'Building type (sub-group)',
      'Project.CreateProject.BuildingFloorAreaBTA': 'Building  floor area (BTA) m²',
      'Project.CreateProject.BuildingFloorAreaLOA': 'Building  floor area (LOA/BOA) m²',
      'Project.CreateProject.BreeamScheme': 'BREEAM Scheme',
      'Project.CreateProject.BreeamSchemeValue': 'New Construction',
      'Project.CreateProject.BreeamVersion': 'BREEAM Version',
      'Project.CreateProject.BreeamSeIssueNumber': 'BREEAM SE v6.0 technical manual issue number',
      'Project.CreateProject.BreeamSeIssueNumberValue': 'Issue 6.0',
      'Project.CreateProject.ProjectType': 'Project type',
      'Project.CreateProject.BuildingTypeTransport': 'What is the building type, for the purpose of the Transport section?',
      'Project.CreateProject.AssessmentStage': 'Assessment stage',
      'Project.CreateProject.AssessmentStageValue': 'Pre-assessment',
      'Project.CreateProject.BuildingDetails': 'Building details',
      'Project.CreateProject.HeatingSystemType': 'Building services - heating system type',
      'Project.CreateProject.CoolingSystemType': 'Building services - cooling system type',
      'Project.CreateProject.HotWaterSystemType': 'Building services - domestic hot water system type',
      'Project.CreateProject.BuildingServicesControls': 'Building services - controls',
      'Project.CreateProject.HeatedOperationalArea': 'If applicable, does this industrial building have a heated operational area?',
      'Project.CreateProject.WaterHeatingTotalConsumption':
        'Does water heating contribute less than 10% of the buildings total energy consumption?',
      'Project.CreateProject.RefrigerationAndStorageSystems': 'Commercial/industrial refrigeration and storage systems',
      'Project.CreateProject.UserTransportation': 'Building user transportation systems (lifts and/or escalators)',
      'Project.CreateProject.LaboratoryFunctionAndSize': 'Laboratory function/area and size category',
      'Project.CreateProject.LaboratoryContainmentLevel': 'Laboratory containment level',
      'Project.CreateProject.FumeCupboard': 'Fume cupboard(s) and/or other containment devices',
      'Project.CreateProject.UnregulatedWaterUsePresent': 'Unregulated water uses present? (e.g. vehicle wash system, irrigation)',
      'Project.CreateProject.IndustrialBuildingOfficeArea': 'If applicable, does this industrial building have an office area?',
      'Project.CreateProject.RetailBuildingOfficeArea': 'If applicable, does this retail building have an office area?',
      'Project.CreateProject.InternalLightingResidentialBuilding':
        'If applicable, will internal lighting be installed in this residential building?',
      'Project.CreateProject.ShellCoreLighting': 'Regarding Shell & Core or Shell Only, is external lighting specified for this building?',
      'Project.CreateProject.CooledOperationalArea': 'If applicable, does this industrial building have a cooled operational area?',
      'Project.CreateProject.CommunalHVACSystem':
        'If applicable, will this residential building have Communal HVAC systems specified/installed?',
      'Project.CreateProject.woodLegallyHarvested':
        'The building is dedicated to extraction, storage, transport or manufacture of fossil fuels.',
      'Project.CreateProject.FossilFuelsManufacture':
        'The building is dedicated to extraction, storage, transport or manufacture of fossil fuels.',

      'Project.CreateProject.ProjectDetails': 'Project details',
      'Project.CreateProject.CreatingCertification1': 'You are now creating a new certification project according to the latest BREEAM-SE v.6.0',
      'Project.CreateProject.CreatingCertificatio2': '*',
      'Project.CreateProject.CreatingCertificatio3': '*',
      'Project.CreateProject.CreatingCertificatio4': '*',
      'Project.CreateProject.ProjectCreated': 'Project created',
      'Project.CreateProject.Continue': 'Your project has been created. Do you want to continue to do a pre-assessment?',
      'Project.CreateProject.TakeMeHome': 'Take me home',
      'Project.CreateProject.PreAssessment': 'Pre-assessment',
      'Project.CreateProject.UnsavedChanges': 'Unsaved changes',
      'Project.CreateProject.UploadPhoto': 'Upload project photo',
      'Project.CreateProject.UploadFile': 'Upload file',
      'Project.CreateProject.UploadDeveloperLogo': 'Upload real estate developer logo',
      'Project.CreateProject.Yes': 'Yes',
      'Project.CreateProject.No': 'No',
      'Project.CreateProject.LeaveWithoutSaving': 'Do you want to leave without saving?',
      'Project.CreateProject.Leave': 'Leave',
      'Project.CreateProject.ExcelentRating': 'Good possibility for Excellent/Outstanding rating level',
      'Project.CreateProject.VeryGoodRating': 'Possible to reach Very Good rating',
      'Project.CreateProject.LowRating':
        'BREEAM-SE certification might not be possible (or low rating) because the project is already under construction. We advise you to certify with BREEAM In-Use instead.',
      'Project.CreateProject.CommencedSourcing':
        ' If the project has not commenced sourcing, it is urgent to initiate this task and secure confirmation of legal harvesting.',
      'Project.CreateProject.LegalHarvesting':
        'If wood materials does not have confirmation of legal harvesting, the project cannot be certified with BREEAM-SE.',
      // 'Project.CreateProject.EditProject': 'Edit project details',
      'Project.CreateProject.UpdatingInformation': 'Updating information may affect your pre-assessment',
      'Project.CreateProject.IfYouEdit':
        'If you edit your answers you might need to answer additional questions in the pre-assessment for it to be accurate, do you still want to edit project deails?',
      'Project.CreateProject.Save': 'Save',
      'Project.CreateProject.Cancel': ' Cancel',

      //Login
      'Project.Login.SignIn': 'Sign in',
      'Project.Login.DontHaveAccount': "Don't have an account?",
      'Project.Login.ContactUs': "Contact us via our website and let's talk!",
      'Project.Login.EmailAddress': 'Enter your email',
      'Project.Login.Password': 'Password ',
      'Project.Login.ForgotYourPassword': ' Forgot your password?',

      //ForgotPassword
      'Project.ForgotPassword.ForgotYourPassword': 'Reset password ',
      'Project.ForgotPassword.TellUsYourEmail':
        "Tell us the email address associated with your account, and we'll send you an email with a link to reset your password.",
      'Project.ForgotPassword.EmailAddress': 'Enter your email  ',
      'Project.ForgotPassword.ResetPasswordButton': 'Reset password ',
      'Project.ForgotPassword.BackToLogin': 'Back to login page ',

      //SetUpNewPassword
      'Project.SetUpNewPassword.SetUpNewPassword': 'Setup new password',
      'Project.SetUpNewPassword.PleaseEnterNew':
        'Please enter a new password that you would like to use. Your new password needs to be at least 8 characters long and include at least one special character.',
      'Project.SetUpNewPassword.EnterNewPassword': 'Enter new password',
      'Project.SetUpNewPassword.RepeatNewPassword': 'Repeat new password',
      'Project.SetUpNewPassword.SaveAndContinue': 'Save & continue',

      //InviteNewUser
      'Project.InviteNewUser.InviteNewUser': 'Invite New User',
      'Project.InviteNewUser.EnterEmailAddress': 'Enter email address',
      'Project.InviteNewUser.Role': 'Assign Role',
      'Project.InviteNewUser.Project': 'Add to Projects',
      'Project.InviteNewUser.Cancel': 'Cancel',
      'Project.InviteNewUser.SendInvite': 'Send invite',

      //DeleteProjectModal
      'Project.Delete.AreYouSure': 'Are you sure you want to delete this project?',
      'Project.Delete.PleaseBeAware':
        'Please be aware that after deleting this project all of the files from this project will be deleted.',
      'Project.Delete.Cancel': ' Cancel',
      'Project.Delete.Delete': 'Delete',

      //CreateAccount
      'Project.CreateAccount.GetToKnowYou': "Let's get to know you a bit better",
      'Project.CreateAccount.AddingDetails': 'Start by adding your details',
      'Project.CreateAccount.FirstName': 'First name',
      'Project.CreateAccount.LastName': 'Last name',
      'Project.CreateAccount.Email': 'Email',
      'Project.CreateAccount.Company': 'Company',
      'Project.CreateAccount.Role': 'Role',
      'Project.CreateAccount.AdvisoryProfessional': 'Advisory Professional',
      'Project.CreateAccount.Assessor': 'Assessor',
      'Project.CreateAccount.Developer': 'Developer',
      'Project.CreateAccount.CreatePassword': 'Create a password',
      'Project.CreateAccount.ConfirmPassword': 'Confirm password',
      'Project.CreateAccount.GetStarted': 'Get started',

      //userSettings
      'Project.UserSettings.Settings': 'Settings',
      'Project.UserSettings.FirstName': 'First name',
      'Project.UserSettings.LastName': 'Last name',
      'Project.UserSettings.Email': 'Email',
      'Project.UserSettings.CompanyName': 'Company name',
      'Project.UserSettings.Role': 'Role',
      'Project.UserSettings.Save': 'Save',
      'Project.UserSettings.LogOut': 'Logout',

      //PreAssessment
      'Project.PreAssessment.0to21Credits': '0 of 21 credits targeted (0%)',
      'Project.PreAssessment.Available': 'Available:',
      'Project.PreAssessment.Credit': 'credit',
      'Project.PreAssessment.Exemplary': 'exemplary',
      'Project.PreAssessment.Target': 'Target',
      'Project.PreAssessment.TargetCO2': 'Credits goal (Criteria 3-5)',
      'Project.PreAssessment.Potential': 'Potential',
      'Project.PreAssessment.yes': 'yes',
      'Project.PreAssessment.no': 'no',
      'Project.PreAssessment.potential': 'potential',
      'Project.PreAssessment.Category': 'Category',
      'Project.PreAssessment.RatingLevel': 'Rating level',
      'Project.PreAssessment.AlthoughTheOverall': 'Although the overall score is within the rating level ',
      'Project.PreAssessment.VeryGood': 'Very Good',
      'Project.PreAssessment.TheRatingIs': ' the rating is being restricted by the minimum standard levels, which is ',
      'Project.PreAssessment.GOOD': 'GOOD',
      'Project.PreAssessment.MinimumStandard': 'Minimum standards:',
      'Project.PreAssessment.EUTaxonomy': 'EU Taxonomy:',
      'Project.PreAssessment.CO2Target': 'CO2 Target (A1-A5)',
      'Project.PreAssessment.ViewSummary': 'View summary',
      'Project.PreAssessment.Criteria': 'Criteria',
      'Project.PreAssessment.CriteriaTabs': 'Criteria',
      'Project.PreAssessment.ChecklistTables': 'Checklist & tables',
      'Project.PreAssessment.ComplianceNotes': 'Compliance notes',
      'Project.PreAssessment.Methodology': 'Methodology',
      'Project.PreAssessment.AdditionalInfo': 'Additional info',
      'Project.PreAssessment.DemonstrateCompilance': 'The following is required to demonstrate compliance:',
      'Project.PreAssessment.TablesAndMore': 'Tables & more information',
      'Project.PreAssessment.EvidenceRequired': 'Evidence required',
      'Project.PreAssessment.Criteria8to9': 'Criteria 8-9',
      'Project.PreAssessment.Eller': 'OR',
      'Project.PreAssessment.NextCategory': 'Next category',
      'Project.PreAssessment.DownloadSummary': 'Download summary',
      'Project.PreAssessment.DownloadFullPreAssessment': 'Download full pre-assessment',
      'Project.PreAssessment.DownloadPreAssessment': 'Download pre-assessment',
      'Project.PreAssessment.ExportToExcel': 'Export to pre-assesment excel tool (SGBC excel)',
      'Project.PreAssessment.ToBeAligned': 'To be aligned with EU taxonomy you need to target criteria 8-9 (Checklist A1, section 3i)',
      'Project.PreAssessment.OfficeBuilding': 'Office building',
      'Project.PreAssessment.ConsiderateConstruction': 'Considerate construction',
      'Project.PreAssessment.EasyToAchieve': 'Easy to achieve, low cost',
      'Project.PreAssessment.MediumDifficulty': 'Medium difficulty, costs associated with it',
      'Project.PreAssessment.AdministrativeHeavy': 'Administrative heavy credit, costs associated',
      'Project.PreAssessment.MinimumStandards': 'Minimum standards',
      'Project.PreAssessment.ThereIsAMinimum': 'There is a minimum standard requirement for this credit:',
      'Project.PreAssessment.IssueToolTip': 'Read more about the criteria in the BREEAM-SE Manual',
      //PDF
      'Project.PreAssessment.PreAssessmentSummary': 'Pre-assessment summary',
      'Project.PreAssessment.ScoreCard': 'Score Card',

      //ProjectSummary
      'Project.ProjectSummary.CreditDetails': 'Credit details',
      'Project.ProjectSummary.MinimumStandards': 'Minimum Standards',
      'Project.ProjectSummary.EUTaxonomy': 'EU Taxonomy',
      'Project.ProjectSummary.ToDoListRequired1': 'The following indicators',
      'Project.ProjectSummary.ToDoListRequired2': 'need',
      'Project.ProjectSummary.ToDoListRequired3': 'to be addressed at the early design stage',
      'Project.ProjectSummary.ToDoListOptional1': 'The following indicators',
      'Project.ProjectSummary.ToDoListOptional2': 'should',
      'Project.ProjectSummary.ToDoListOptional3': 'be started at the early design stage',
      'Project.ProjectSummary.CO2Target': 'CO2 Target (A1-A5)',
      'Project.ProjectSummary.CO2TargetSummary': 'CO2 Target',
      'Project.ProjectSummary.DownloadPreAssessment': 'Download pre-assessment',
      'Project.ProjectSummary.Targeted': 'Targeted',
      'Project.ProjectSummary.Potential': 'Potential',
      'Project.ProjectSummary.NotTargeted': 'Not targeted',
      'Project.ProjectSummary.Compliant': 'Compliant',
      'Project.ProjectSummary.NotCompliant': 'Not compliant',
      'Project.ProjectSummary.NoOfCreditsTargeted': 'No. credits achieved (targeted)',
      'Project.ProjectSummary.PercentageOfCreditsTargeted': '% credits achieved (targeted)',
      'Project.ProjectSummary.CriteriaCompliant': 'Criteria compliant',
      'Project.ProjectSummary.TargetCompliantCO2': 'Target compliant',
      'Project.ProjectSummary.CriteriaCompliantText': 'Criteria compliant text',
      'Project.ProjectSummary.Issue': 'Issue',
      'Project.ProjectSummary.Section': 'Section',
      'Project.ProjectSummary.NoCreditsAvailable': 'No. credits available',
      'Project.ProjectSummary.NoCreditsAchieved': 'No. credits achieved (targeted)',
      'Project.ProjectSummary.CreditsAchieved (targeted)': '% credits achieved (targeted)',
      'Project.ProjectSummary.NoOfPotentialCredits': 'No. of potential credits',
      'Project.ProjectSummary.PotentialCredits': '% potential credits',
      'Project.ProjectSummary.EUTaxonomyRequirement': 'EU Taxonomy requirement',
      'Project.ProjectSummary.Good': 'Good',
      'Project.ProjectSummary.VeryGood': 'Very Good',
      'Project.ProjectSummary.Excellent': 'Excellent',
      'Project.ProjectSummary.Outstanding': 'Outstanding',
      'Project.ProjectSummary.Pass': 'Pass',
      //ProjectSummaryButtons
      'Project.ProjectSummary.CompliantButton': 'Compliant',
      'Project.ProjectSummary.NotCompliantButton': 'Not compliant',
      'Project.ProjectSummary.NotApplicableButton': 'Not applicable',
      'Project.ProjectSummary.AlignedButton': 'Aligned',
      'Project.ProjectSummary.NotAlignedButton': 'Not aligned',
      'Project.ProjectSummary.TargetNotSetButton': 'Target not set',
      'Project.ProjectSummary.CO2ToolTipForSpecialCases': 'Criterias 1, 3, 4 & 5 are not applicable to building types that are not covered by the Boverket Climate Declaration act, such as refurbishment, industry/warehouse, etc.',
      //ProjectSummaryCAard ToolTip
      'Project.ProjectSummary.MinimumStandards_a': 'You are currently NOT compliant with all the necessary Minimum Standards for your target rating. Visit the summary for a detailed view.',
      'Project.ProjectSummary.MinimumStandards_b': 'You are currently compliant with all the necessary Minimum Standards for your target rating. Visit the summary for a detailed view.',
      'Project.ProjectSummary.EUTaxonomy_a': 'You are currently aligned with all the necessary EU taxonomy requirements. Visit the summary for a detailed view.',
      'Project.ProjectSummary.EUTaxonomy_b': 'You are currently NOT aligned with all the necessary EU taxonomy requirements. Visit the summary for a detailed view.',
      'Project.ProjectSummary.CO2_a': 'Your chosen Mat 1 credits is under your CO2 target level. If you want to achieve your target you need at least [2] credits under Mat 01',
      'Project.ProjectSummary.CO2_b': 'Your chosen Mat 1 credits is aligned with your CO2 target level',
      'Project.ProjectSummary.CO2_c': 'Target Not Set'
    }
  },
  sv: {
    translation: {
      //General
      'Project.General.ErrorFetchingData': 'Det gick inte att hämta data. Vänligen försök igen senare.',
      'Project.General.Loading': 'Läser in...',

      //NavTabs
      'Project.NavTabs.ProjectDetails': 'Projektdetaljer',
      'Project.NavTabs.ScoreCard': 'Poänglista',
      'Project.NavTabs.SummaryOfBuildingPerformance': 'Sammanfattning byggnadsprestanda', //'Sammanfattning av byggnadens prestanda',

      //AllProject
      'Project.AllProject.Title': 'Alla projekt',
      'Project.AllProject.NoProjectsFound': 'Här var det tomt',
      'Project.AllProject.InviteUser': ' Bjud in användare',
      'Project.AllProject.NewProject': 'Skapa projekt',
      'Project.AllProject. NotCreatedProjet': 'Det verkar som att du inte har skapat något projekt ännu. ',
      'Project.AllProject.StartWithThat': 'Låt oss börja med det!',

      //ProjectCard
      'Project.ProjectCard.DeleteProject': 'Radera projekt',
      'Project.ProjectCard.TargetRating': 'Målbetyg',
      'Project.ProjectCard.BuildingType': 'Byggnadstyp',
      'Project.ProjectCard.BuildingStage': 'Byggskede',
      'Project.ProjectCard.TargetCO2(A1-A5)': 'CO2 mål (A1 - A5)',
      'Project.ProjectCard.PreAssessment': 'Förstudie',
      'Project.ProjectCard.Edit': 'Redigera projekt',
      'Project.ProjectCard.Pre-assessmentEarlyDesignStage': 'Förstudie / Programhandling',
      'Project.ProjectCard.DesignStage': 'Systemhandling / Bygghandling',
      'Project.ProjectCard.Construction': 'Byggskede / Produktion',
      'Project.ProjectCard.Options': 'Alternativ',

      //CreateNewProject
      'Project.CreateProject.CreateNewProject': 'Skapa nytt projekt',
      'Project.CreateProject.CreateProject': 'Skapa projekt',
      'Project.CreateProject.InitialQuestions': 'Initiala frågor',
      'Project.CreateProject.Stage': 'Vilket projektskede befinner ni er i?',
      'Project.CreateProject.WoodLegallyHarvested': 'Kan projektet säkerställa att träprodukterna är lagligt avverkade och inköpta?',
      'Project.CreateProject.AssetDetails': 'Byggnadsuppgifter',
      'Project.CreateProject.BuildingName': 'Projektnamn',
      'Project.CreateProject.BuildingAddress': 'Adress',
      'Project.CreateProject.Region': 'Region',
      'Project.CreateProject.Postcode': 'Postnummer',
      'Project.CreateProject.Country': 'Land',
      'Project.CreateProject.AssessmentDetails': 'Bedömningsdetaljer',
      'Project.CreateProject.TargetRating': 'Målbetyg',
      'Project.CreateProject.EmbodiedCO2Target': 'Inbyggt kgCO2e/m2 (BTA) målnivå A1-A5',
      'Project.CreateProject.Done': 'Klar',
      'Project.CreateProject.Error': 'Det saknas innehåll',
      'Project.CreateProject.TargetNotSet': 'Målnivå inte fastställt',
      'Project.CreateProject.SetTarget': 'Sätt målnivå',
      'Project.CreateProject.BuildingTypeMain': 'Byggnadstyp (huvudbeskrivning)',
      'Project.CreateProject.BuildingTypeSub': 'Byggnadstyp (undergrupp)',
      'Project.CreateProject.BuildingFloorAreaBTA': 'Byggnadens bruttoarea (BTA) m2',
      'Project.CreateProject.BuildingFloorAreaLOA': 'Nettoarea som ingår i certifiering (LOA/BOA) (m2)',
      'Project.CreateProject.BreeamScheme': 'BREEAM-SE manual',
      'Project.CreateProject.BreeamSchemeValue': 'Nybyggnad',
      'Project.CreateProject.BreeamVersion': 'BREEAM-SE manual version',
      'Project.CreateProject.BreeamSeIssueNumber': 'BREEAM-SE v6.0 teknisk manual version',
      'Project.CreateProject.BreeamSeIssueNumberValue': 'Utgåva 6.0',
      'Project.CreateProject.ProjectType': 'Projekttyp',
      'Project.CreateProject.BuildingTypeTransport': 'Välj detaljerad byggnadstyp (för Transport indikatorerna) ',
      'Project.CreateProject.AssessmentStage': 'Certifieringsskede',
      'Project.CreateProject.AssessmentStageValue': 'Förstudie',
      'Project.CreateProject.BuildingDetails': 'Byggnadsinformation',
      'Project.CreateProject.HeatingSystemType': 'Byggnadsteknik - typ av värmesystem',
      'Project.CreateProject.CoolingSystemType': 'Byggnadsteknik - typ av kylsystem',
      'Project.CreateProject.HotWaterSystemType': 'Byggnadsteknik - varmvattensystem',
      'Project.CreateProject.BuildingServicesControls': 'Byggnadsteknik - kontrollinstrument',
      'Project.CreateProject.HeatedOperationalArea': 'Om tillämpligt, har denna industribyggnad ett uppvärmt driftsområde?',
      'Project.CreateProject.WaterHeatingTotalConsumption':
        'Bidrar uppvärmning av vatten till mindre än 10% av byggnadens totala energiförbrukning?',
      'Project.CreateProject.RefrigerationAndStorageSystems': 'Kommersiella/industriella kyl- och lagringssystem',
      'Project.CreateProject.UserTransportation': 'Transportsystem för byggnadsanvändare (hissar och/eller rulltrappor)',
      'Project.CreateProject.LaboratoryFunctionAndSize': 'Laboratoriefunktion/område och storlekskategori',
      'Project.CreateProject.LaboratoryContainmentLevel': 'Laboratoriets skyddsnivå',
      'Project.CreateProject.FumeCupboard': 'Dragskåp och/eller andra skyddsanordningar',
      'Project.CreateProject.UnregulatedWaterUsePresent': 'Förekommer oreglerad vattenanvändning? (t.ex. fordonstvätt, bevattning)',
      'Project.CreateProject.IndustrialBuildingOfficeArea': 'Om tillämpligt, har denna industribyggnad ett kontorsområde?',
      'Project.CreateProject.RetailBuildingOfficeArea': 'Om tillämpligt, har denna detaljhandelsbyggnad ett kontorsområde?',
      'Project.CreateProject.InternalLightingResidentialBuilding':
        'Om tillämpligt, kommer internbelysning att installeras i detta bostadshus?',
      'Project.CreateProject.ShellCoreLighting':
        'När det gäller oinredda byggnader med basinstallationer eller oinredda byggnader utan basinstallationer, är extern belysning specificerad för denna byggnad?',
      'Project.CreateProject.CooledOperationalArea': 'Om tillämpligt, har denna industribyggnad ett kylt driftsområde?',
      'Project.CreateProject.CommunalHVACSystem':
        'Om tillämpligt, kommer detta bostadshus att ha kommunala HVAC-system specificerade/installerade?',
      'Project.CreateProject.FossilFuelsManufacture':
        'Byggnaden är avsedd för utvinning, lagring, transport eller tillverkning av fossila bränslen.',
      'Project.CreateProject.woodLegallyHarvested':
        'Byggnaden är avsedd för utvinning, lagring, transport eller tillverkning av fossila bränslen',
      'Project.CreateProject.ProjectDetails': 'Projektdetaljer',
      'Project.CreateProject.CreatingCertification1': 'Du skapar nu en ny certifiering i enlighet med den senaste versionen av BREEAM-SE.',
      'Project.CreateProject.CreatingCertification2': '*All immateriell äganderätt, inklusive upphovsrätt, vad gäller BREEAM-SE och eventuella tillägg till och anpassningar av BREEAM International New Construction tillhör SGBC och BRE Global Limited (BRE). Denna verktyg är utvecklat av Net Zero Build Ab, licenserad företag som erbjuder BREEAM-SE AP/Assessor tjänster och hjälper sina kunder med BREEAM-SE miljöcertifieringar.',
      'Project.CreateProject.CreatingCertification3': 'Företagslicens ref. nr: Ftg SE 051',
      'Project.CreateProject.CreatingCertification4': 'Licensierad BREEAM-SE AP & Assessor: Kristijan Jordanov - NZBA BSEA 032',
      //'Du skapar nu en ny certifiering i enlighet med den senaste versionen av BREEAM-SE.',
      'Project.CreateProject.ProjectCreated': 'Projektet är skapat',
      'Project.CreateProject.Continue':
        'Ditt projekt har skapats. Nästa steg är att göra en förstudie (pre-assessment) för att bedöma om projektet kan uppnå önskat betyg, vill du fortsätta?',
      'Project.CreateProject.TakeMeHome': 'Till översikt',
      'Project.CreateProject.PreAssessment': 'Fortsätt till förstudie',
      'Project.CreateProject.UnsavedChanges': 'Lämna utan att spara?',
      'Project.CreateProject.UploadPhoto': 'Lägg till projektbild',
      'Project.CreateProject.UploadFile': 'Ladda upp bild',
      'Project.CreateProject.UploadDeveloperLogo': ' Lägg till företagslogga',
      'Project.CreateProject.Yes': 'Ja',
      'Project.CreateProject.No': 'Nej',
      'Project.CreateProject.LeaveWithoutSaving':
        'Om du lämnar nu kommer du att förlora den information du skrivit. Är du säker på att du vill lämna?',
      'Project.CreateProject.Leave': 'Lämna',
      'Project.CreateProject.ExcelentRating': 'Goda förutsättningar för Excellent/Outstanding I betyg.',
      'Project.CreateProject.VeryGoodRating': 'Möjlighet att uppnå Very Good i betyg',
      'Project.CreateProject.LowRating':
        'BREEAM-SE-certifiering är eventuellt inte möjlig (eller endast med lågt betyg) eftersom projektet redan är under produktion. Vår rekommendation är därför att byggnaden istället certifieras enligt BREEAM In-Use',
      'Project.CreateProject.CommencedSourcing': 'Om projektet ej påbörjat inköp är detta bråttom och viktigt att kravställa. ',
      'Project.CreateProject.LegalHarvesting':
        'Om projektet redan köpt in trä eller träbaserade produkter som byggs in som ej är lagligt avverkat och inköpt kan projektet ej certifiera projektet i BREEAM SE',
      'Project.CreateProject.UpdatingInformation': 'Uppdatering av information kan påverka din förstudie',
      'Project.CreateProject.IfYouEdit':
        ' Om du redigerar dina svar kan du behöva svara på ytterligare frågor i förstudien för att den ska bli korrekt, vill du fortfarande redigera?',
      // 'Project.CreateProject.EditProject': 'Redigera projektinformation',
      'Project.CreateProject.Save': 'Spara',
      'Project.CreateProject.Cancel': 'Avbryt',

      //Login
      'Project.Login.SignIn': 'Logga in',
      'Project.Login.DontHaveAccount': 'Har du inte ett konto?',
      'Project.Login.ContactUs': 'Kontakta oss via vår hemsida så hjälper vi dig',
      'Project.Login.EmailAddress': 'E-postadress ',
      'Project.Login.Password': 'Lösenord',

      'Project.Login.ForgotYourPassword': 'Har du glömt lösenordet?',

      //ForgotPassword
      'Project.ForgotPassword.ForgotYourPassword': 'Glömt ditt lösenord?',
      'Project.ForgotPassword.TellUsYourEmail':
        'Skriv in din email som är kopplad till ditt konto så skickar vi en länk för att återställa ditt lösenord. ',
      'Project.ForgotPassword.EmailAddress': 'E-postadress',
      'Project.ForgotPassword.ResetPasswordButton': 'Fortsätt',
      'Project.ForgotPassword.BackToLogin': 'Tillbaka till login',

      //SetUpNewPassword
      'Project.SetUpNewPassword.SetUpNewPassword': 'Skapa nytt lösenord',
      'Project.SetUpNewPassword.PleaseEnterNew': 'Ange ett nytt lösenord som du vill använda.',
      'Project.SetUpNewPassword.EnterNewPassword': 'Skriv ditt nya lösenord',
      'Project.SetUpNewPassword.RepeatNewPassword': 'Upprepa ditt nya lösenord ',
      'Project.SetUpNewPassword.SaveAndContinue': 'Spara & fortsätt',

      //InviteNewUser
      'Project.InviteNewUser.InviteNewUser': 'Bjud in ny användare',
      'Project.InviteNewUser.EnterEmailAddress': 'Skriv in e-postadress',
      'Project.InviteNewUser.Role': 'Tilldela användaren en roll',
      'Project.InviteNewUser.Project': 'Vänligen välj projektet du vill bjuda in användaren till', //'Bjud in användaren till projekt',
      'Project.InviteNewUser.Cancel': 'Avbryt',
      'Project.InviteNewUser.SendInvite': 'Skicka inbjudan',

      //InviteNewUser
      'Project.Delete.AreYouSure': 'Är du säker på att du vill radera detta projekt?',
      'Project.Delete.PleaseBeAware': 'Om du raderar detta projekt kommer all information att tas bort.',
      'Project.Delete.Cancel': 'Avbryt',
      'Project.Delete.Delete': 'Radera',

      //CreateAccount
      'Project.CreateAccount.GetToKnowYou': 'Skapa ett konto', //'Låt oss lära känna dig lite bättre',
      'Project.CreateAccount.AddingDetails': 'Fyll i dina uppgifter nedan för att börja använda Net Zero Build', //'Du kan börja med att fylla i dina uppgifter',
      'Project.CreateAccount.FirstName': 'Förnamn',
      'Project.CreateAccount.LastName': 'Efternamn',
      'Project.CreateAccount.Email': 'E-postadress',
      'Project.CreateAccount.Company': 'Företag',
      'Project.CreateAccount.Role': 'Roll',
      'Project.CreateAccount.AdvisoryProfessional': 'BREEAM-SE AP',
      'Project.CreateAccount.Assessor': 'BREEAM-SE Assessor',
      'Project.CreateAccount.Developer': 'Byggherre',
      'Project.CreateAccount.CreatePassword': 'Skapa lösenord',
      'Project.CreateAccount.ConfirmPassword': 'Bekräfta lösenord',
      'Project.CreateAccount.GetStarted': 'Skapa konto',  //'Fortsätt',

      //userSettings
      'Project.UserSettings.Settings': 'Inställningar',
      'Project.UserSettings.FirstName': 'Förnamn',
      'Project.UserSettings.LastName': 'Efternamn',
      'Project.UserSettings.Email': 'E-postadress',
      'Project.UserSettings.CompanyName': 'Företagsnamn',
      'Project.UserSettings.Role': 'Roll',
      'Project.UserSettings.Save': 'Spara',
      'Project.UserSettings.LogOut': 'Logga ut',

      //PreAssessment
      'Project.PreAssessment.0to21Credits': '0 av 21 poäng valda (0%)',
      'Project.PreAssessment.Available': 'Tillgängliga poäng:',
      'Project.PreAssessment.Credit': 'poäng',
      'Project.PreAssessment.Exemplary': 'exemplarisk p.',
      'Project.PreAssessment.Target': 'Målbetyg',
      'Project.PreAssessment.TargetCO2': 'Poängmal (Kriterier 3-5)', //
      'Project.PreAssessment.Potential': 'Potentiellt betyg',
      'Project.PreAssessment.PotentialPoints': 'Potentiella poäng',
      'Project.PreAssessment.yes': 'ja',
      'Project.PreAssessment.no': 'nej',
      'Project.PreAssessment.potential': 'potentiell',
      'Project.PreAssessment.Category': 'Kategori',
      'Project.PreAssessment.RatingLevel': 'Nuvarande betyg',
      'Project.PreAssessment.AlthoughTheOverall': 'Även om det övergripande betyget ligger inom betygsnivån',
      'Project.PreAssessment.TheRatingIs': ' begränsas betyget av minimikravnivåerna, som uppfyller bara ',
      'Project.PreAssessment.MinimumStandard': 'Minimikrav:',
      'Project.PreAssessment.VeryGood': 'Very Good',
      'Project.PreAssessment.GOOD': 'GOOD',
      'Project.PreAssessment.EUTaxonomy': 'EU Taxonomi:',
      'Project.PreAssessment.CO2Target': 'CO2 mål (A1-A5)',
      'Project.PreAssessment.ViewSummary': 'Visa sammanfattning',
      'Project.PreAssessment.Criteria': 'Kriterier',
      'Project.PreAssessment.CriteriaTabs': 'Bedömningskriterier', //
      'Project.PreAssessment.ChecklistTables': 'Checklistor och tabeller', //'Checklista & tabeller',
      'Project.PreAssessment.ComplianceNotes': 'Tolkning av bedömningskriterier', //'Anmärkningar om efterlevnad',
      'Project.PreAssessment.Methodology': 'Metod', //'Metodik',
      'Project.PreAssessment.DemonstrateCompilance': 'Följande krävs för visa att projektet uppfyller kraven:',
      'Project.PreAssessment.AdditionalInfo': 'Ytterligare information', //'Ytterligare info',
      'Project.PreAssessment.TablesAndMore': 'Tabeller och mer information',
      'Project.PreAssessment.EvidenceRequired': 'Bevisning', //'Bevis som krävs',
      'Project.PreAssessment.Criteria8to9': 'Kriterier 8-9',
      'Project.PreAssessment.Eller': 'ELLER',
      'Project.PreAssessment.NextCategory': 'Nästa kategori',
      'Project.PreAssessment.DownloadSummary': 'Ladda ner sammanfattning',
      'Project.PreAssessment.DownloadFullPreAssessment': 'Ladda ner full rapport',
      'Project.PreAssessment.DownloadPreAssessment': 'Ladda ner pre-assessment',
      'Project.PreAssessment.ExportToExcel': 'Ladda ner BREEAM_SE_v6.0_Pre-Assessment_Tool',
      'Project.PreAssessment.ToBeAligned': 'To be aligned with EU taxonomy you need to target criteria 8-9 (Checklist A1, section 3i)',
      'Project.PreAssessment.OfficeBuilding': 'Kontorsbyggnad',
      'Project.PreAssessment.ConsiderateConstruction': 'Hänsynsfull konstruktion',
      'Project.PreAssessment.EasyToAchieve': 'Lätt att uppnå, låg kostnad',
      'Project.PreAssessment.MediumDifficulty': 'Medelhög svårighetsgrad, visa kostnader förekommer med poängen',
      'Project.PreAssessment.AdministrativeHeavy': 'Administrativt tungt poäng, eventuellt hög kostnad förekommer med poängen',
      'Project.PreAssessment.MinimumStandards': 'Minimikrav',
      'Project.PreAssessment.ThereIsAMinimum': 'Det finns minimikrav att uppnå för denna kredit:',
      'Project.PreAssessment.IssueToolTip': 'Läs mer om kriterierna i BREEAM-SE Manualen',

      //PDF
      'Project.PreAssessment.PreAssessmentSummary': 'Sammanfattning av förhandsbedömning',
      'Project.PreAssessment.ScoreCard': 'Poänglista',

      //ProjectSummary
      'Project.ProjectSummary.CreditDetails': 'Bedömningskriterier',
      'Project.ProjectSummary.MinimumStandards': 'Minimikrav',
      'Project.ProjectSummary.EUTaxonomy': 'EU Taxonomi',
      'Project.ProjectSummary.ToDoListRequired1': 'Följande indikatorer',
      'Project.ProjectSummary.ToDoListRequired2': 'måste',
      'Project.ProjectSummary.ToDoListRequired3': 'hanteras i tidigt programhandlingsskede',
      'Project.ProjectSummary.ToDoListOptional1': 'Följande indikatorer',
      'Project.ProjectSummary.ToDoListOptional2': 'bör',
      'Project.ProjectSummary.ToDoListOptional3': 'påbörjas i tidigt programhandlingsskede',
      'Project.ProjectSummary.CO2Target': 'CO2 mål (A1-A5)',
      //'Project.ProjectSummary.Co2Target': 'CO2 mål',
      'Project.ProjectSummary.CO2TargetSummary': 'CO2 målnivå',
      'Project.ProjectSummary.DownloadPreAssessment': 'Ladda ner pre-assessment', //'Ladda ner förstudie',
      'Project.ProjectSummary.Targeted': 'Valda poäng', //'Målnivå',
      'Project.ProjectSummary.Potential': 'Potentiella poäng', //'Potentiell målnivå',
      'Project.ProjectSummary.NotTargeted': 'Ej valda', //' Målnivå inte fastställd',
      'Project.ProjectSummary.Compliant': 'Linjerad ',
      'Project.ProjectSummary.NotCompliant': 'Inte linjerad',
      'Project.ProjectSummary.NoOfCreditsTargeted': 'Antal valda poäng',
      'Project.ProjectSummary.PercentageOfCreditsTargeted': '% av valda poäng',
      'Project.ProjectSummary.CriteriaCompliant': 'Kriteriet uppfyllelse',
      'Project.ProjectSummary.TargetCompliantCO2': 'Måluppfyllelse',
      'Project.ProjectSummary.CriteriaCompliantText': 'Kriteriebeskrivning-',
      'Project.ProjectSummary.Issue': 'Indikator',
      'Project.ProjectSummary.Section': 'Kategori', //'Avsnitt',
      'Project.ProjectSummary.NoCreditsAvailable': 'Antal poäng tillgängliga', //'Antal kriterier tillgängliga',
      'Project.ProjectSummary.NoCreditsAchieved': 'Antal uppnådda poäng (mål)',
      'Project.ProjectSummary.CreditsAchieved (targeted)': '% av uppnådda poäng (mål)',
      'Project.ProjectSummary.NoOfPotentialCredits': 'Antal potentiella poäng', //'Antal potentiella kriterier',
      'Project.ProjectSummary.PotentialCredits': '% av potentiella poäng', //'% av potentiella kriterier',
      'Project.ProjectSummary.EUTaxonomyRequirement': 'EU-taxonomi krav',
      'Project.ProjectSummary.Good': 'Good',
      'Project.ProjectSummary.VeryGood': 'Very Good',
      'Project.ProjectSummary.Excellent': 'Excellent',
      'Project.ProjectSummary.Outstanding': 'Outstanding',
      'Project.ProjectSummary.Pass': 'Pass',
      //ProjectSummaryButtons
      'Project.ProjectSummary.CompliantButton': 'Uppfyllt',
      'Project.ProjectSummary.NotCompliantButton': 'Ej uppfyllt',
      'Project.ProjectSummary.NotApplicableButton': 'Ej tillämpligt',
      'Project.ProjectSummary.AlignedButton': 'Linjerad',
      'Project.ProjectSummary.NotAlignedButton': 'Inte linjerad',
      'Project.ProjectSummary.TargetNotSetButton': 'Målnivå inte fastställt',
      'Project.ProjectSummary.CO2ToolTipForSpecialCases': 'Kriterium 1, 3, 4 & 5är inte tillämpliga för byggnader som inte omfattas av lagen om klimatdeklaration, sà som exempelvis ombyggnader, industri/verkstad m.m.',
      //    ? t('Project.ProjectSummary.NotCompliantButton') : row[column] === 'Aligned' ? t('Project.ProjectSummary.CompliantButton') : t('Project.ProjectSummary.TargetNotSetButton')    }`}


      //ProjectSummaryCAard ToolTip
      'Project.ProjectSummary.MinimumStandards_a': 'Du uppfyller för närvarande INTE alla nödvändiga minimikrav för ditt målbetyg. Besök sammanfattningen för en detaljerad vy.',
      'Project.ProjectSummary.MinimumStandards_b': 'Du uppfyller för närvarande alla nödvändiga minimikrav för ditt målbetyg. Besök sammanfattningen för en detaljerad vy.',
      'Project.ProjectSummary.EUTaxonomy_a': 'Du är för närvarande linjerad med alla nödvändiga EU-taxonomikrav. Besök sammanfattningen för en detaljerad vy.',
      'Project.ProjectSummary.EUTaxonomy_b': 'Du är för närvarande INTE linjerad med alla nödvändiga EU-taxonomikrav. Besök sammanfattningen för en detaljerad vy.',
      'Project.ProjectSummary.CO2_a': 'Dina valda Mat 1 poäng ligger under din CO2-målnivå. Om du vill uppnå ditt mål behöver du minst [2] poäng under Mat 01',
      'Project.ProjectSummary.CO2_b': 'Dina valda Mat 1 poäng är i linje med din CO2-målnivå',
      'Project.ProjectSummary.CO2_c': 'Målnivå inte fastställt'
    }
  }
};

const lang = localStorage.getItem('lang') || 'sv';

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
