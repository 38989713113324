import { CreateAccountRequest } from 'features/CreateAccount/interfaces/createAccountRequest';
import { CreateProjectRequest } from 'features/CreateProject/interfaces/createProjectRequest';
import { ForgotPasswordRequest } from 'features/ForgotPassword/interfaces/forgotPasswordRequest';
import { InviteNewUserRequest } from 'features/InviteNewUser/interfaces/inviteNewUserRequest';
import { LoginRequest } from 'features/Login/interfaces/loginRequest';
import { CriteriaAnswerRequest } from 'features/PreAssessment/interfaces/criteriaAnswer';
import { SetUpNewPasswordRequest } from 'features/SetUpNewPassword/interfaces/setUpNewPasswordRequest';
import { UserSettingsRequest } from 'features/UserSettings/interfaces/userSettingsRequest';
import { get, post, put } from 'shared/utils/fetchHelper';

const login = (model: LoginRequest) => post('/api/Auth/login', model);
const forgotPassword = (model: ForgotPasswordRequest) => post('/api/Auth/forgot-password', model);
const setUpNewPassword = (model: SetUpNewPasswordRequest) => post('/api/Auth/new-password', model);
const inviteNewUser = (model: InviteNewUserRequest) => post('/api/Project/invite-user', model);
const getUserSettings = () => get('/api/shared/user-settings');
const userSettings = (model: UserSettingsRequest) => post('/api/shared/user-settings', model);
const createAccount = (model: CreateAccountRequest) => post('/api/Auth/register', model);
const getProjects = () => get('/api/project');
const getProject = () => get('/api/project/list');
const userInfoToken = (token: string) => get(`/api/Auth/user-info-token?token=${token}`);
const deleteProject = (id: string) => post('/api/project/delete', { id });
const getCreateProjectOptions = (projectId: string | undefined) => get(`/api/Shared/create-project-data?projectId=${projectId}`);
const getPreAssesmentData = (projectId: string, categoryId: string) => get(`/PreAssessment?ProjectId=${projectId}&categoryId=${categoryId}`);
const getSectors = () => get('/api/Shared/sectors');
const getSubSectors = (id: string) => get(`/api/Shared/sub-sectors?SectorId=${id}`);
const getRegions = () => get('/api/Shared/regions');
const createNewProject = (model: CreateProjectRequest) => post('/api/Project/create', model);
const updateCriteriaAnswer = (model: CriteriaAnswerRequest) => put('/PreAssessment/pre-assessment-scores', model);
const getPreAssesmentSummary = (projectId: string) => get(`/PreAssessment/summary?ProjectId=${projectId}`);
const getBuildingPerformance = (projectId: string) => get(`/Summary?projectId=${projectId}`)
const getPreAssesmentSummaryPdf = (projectId: string) => get(`/PreAssessment/pdf-summary?projectId=${projectId}`)
const getPreAssesmentFullSummaryPdf = (projectId: string) => get(`/PreAssessment/pdf-full-summary?projectId=${projectId}`)
const getExcelSummary = (projectId: string) => get(`/PreAssessment/excel-summary?projectId=${projectId}`)
const uploadPhoto = (formData: FormData ) =>post('/api/Attachment', formData, 'multipart/form-data');

export {
  login,
  forgotPassword,
  setUpNewPassword,
  inviteNewUser,
  createAccount,
  getProjects,
  userInfoToken,
  getProject,
  deleteProject,
  getCreateProjectOptions,
  getSectors,
  getSubSectors,
  getRegions,
  createNewProject,
  getPreAssesmentData,
  getUserSettings,
  userSettings,
  updateCriteriaAnswer,
  getPreAssesmentSummary,
  getBuildingPerformance,
  getPreAssesmentSummaryPdf,
  getPreAssesmentFullSummaryPdf,
  getExcelSummary,
  uploadPhoto
};
