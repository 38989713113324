import { Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NavTab from './NavTab';
import DialogEditWarning from 'features/Dashboard/components/DialogEditWarning';

export default function NavigationTabs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const projectId = params.projectId;

  const srcPath = pathname.split('/')[1];
  const [selectedTab, setSelectedTab] = useState(srcPath);
  const [selected, setSelected] = useState('');
  const [showEditWarning, setShowEditWarning] = useState(false);

  useEffect(() => {
    setSelectedTab(srcPath);
  }, [srcPath]);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

 const handleTabClick = (path: string) => {
    if (path === `/project/${projectId}`) {
      setShowEditWarning(true); // Show modal only for the "Project Details" tab
      setSelected(`${projectId}`);
    } else {
      navigate(path);
    }
  };

  interface TabConfig {
    value: string;
    label: string;
    path: string;
  }

  const tabsConfig: TabConfig[] = [
    ...(projectId
      ? [
          { value: 'project', label: t('Project.NavTabs.ProjectDetails'), path: `/project/${projectId}` },
          { value: 'pre-assessment', label: t('Project.NavTabs.ScoreCard'), path: `/pre-assessment/${projectId}` },
          { value: 'project-summary', label: t('Project.NavTabs.SummaryOfBuildingPerformance'), path: `/project-summary/${projectId}` }
        ]
      : [])
  ];

  if (!tabsConfig.some((tab) => tab.value === srcPath)) {
    return null;
  }

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChange} sx={{ paddingLeft: 3 }}>
        {tabsConfig.map((tab) => (
          <NavTab value={tab.value} label={tab.label} key={tab.value} onClick={() => handleTabClick(tab.path)} />
        ))}
      </Tabs>

      <DialogEditWarning
        open={showEditWarning}
        setOpen={setShowEditWarning}
        projectId={selected}
      />
    </>
  );
}
