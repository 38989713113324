import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from 'shared/components/CustomSnackbar';
import axios from './axiosConfig';

const SetupInterceptors = (
  navigate: any,
  setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>,
  setGeneralError: React.Dispatch<React.SetStateAction<string>>
) => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.removeItem("accessToken");
          navigate('/login');
        }
        if (error.response.status === 400 && !error.response.data?.errors?.length) {
          setOpenSnackBar(true);
          setGeneralError(error.response.data?.message);
        }
      }
      return Promise.reject(error);
    }
  );
};

const RootComponents = () => {
  let navigate = useNavigate();
  const [ran, setRan] = useState(false);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [generalError, setGeneralError] = useState<string>('');

  if (!ran) {
    SetupInterceptors(navigate, setOpenSnackBar, setGeneralError);
    setRan(true);
  }

  useEffect(() => {
    return () => {
      setOpenSnackBar(false);
      setGeneralError('');
    };
  }, []);

  return (
    <CustomSnackbar openSnackbar={openSnackbar} message={generalError} handleClose={() => setOpenSnackBar(false)}></CustomSnackbar>
  );
};

export default RootComponents;
