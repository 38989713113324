import { Tab, styled } from '@mui/material';

const NavTab = styled(Tab)({
  textTransform: 'none',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
});

export default NavTab;
