import React from 'react';
import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RootComponents from 'shared/api/AxiosInterceptorComponent';
import { lazyImport } from 'shared/utils/lazyImport';
import { MainLayout, PublicLayout } from 'shared/components';
import { PreAssessmentLayout } from 'shared/components/Layout/PreAssessmentLayout';

const { Login } = lazyImport(async () => await import('features/Login/Login'), 'Login');
const { CreateAccount } = lazyImport(async () => await import('features/CreateAccount/CreateAccount'), 'CreateAccount');
const { ForgotPassword } = lazyImport(async () => await import('features/ForgotPassword/ForgotPassword'), 'ForgotPassword');
const { SetUpNewPassword } = lazyImport(async () => await import('features/SetUpNewPassword/SetUpNewPassword'), 'SetUpNewPassword');
const { AllProjects } = lazyImport(async () => await import('features/Dashboard/Dashboard'), 'AllProjects');
const { CreateNewProject } = lazyImport(async () => await import('features/CreateProject/CreateNewProject'), 'CreateNewProject');
const { PreAssessment } = lazyImport(async () => await import('features/PreAssessment/PreAssessment'), 'PreAssessment');
const { PdfPreAssessment } = lazyImport(async () => await import('features/PDF/PreAssessment/PdfPreAssessment'), 'PdfPreAssessment');
const { ProjectSummary } = lazyImport(async () => await import('features/ProjectSummary/ProjectSummary'), 'ProjectSummary');
const { PdfSummary } = lazyImport(async () => await import('features/PDF/Summary/PdfSummary'), 'PdfSummary');

const App: React.FC = () => (
  <Router>
    {<RootComponents />}
    <Routes>
      <Route path='/' element={<Navigate to='/login' replace={true} />} />
      <Route path='/login' element={<PublicLayout><Login /></PublicLayout>} />
      <Route path='/forgot-password' element={<PublicLayout><ForgotPassword /></PublicLayout>} />
      <Route path='/setup-new-password/:token' element={<PublicLayout><SetUpNewPassword /></PublicLayout>} />
      <Route path='/create-account/:token' element={<PublicLayout><CreateAccount /></PublicLayout>} />
      <Route path='/home' element={<MainLayout><AllProjects /></MainLayout>} />
      <Route path='/project/:projectId?' element={<MainLayout><CreateNewProject /></MainLayout>} />
      <Route path='/pre-assessment/:projectId' element={<PreAssessmentLayout><PreAssessment /></PreAssessmentLayout>} />
      <Route path='/pdf/pre-assessment/:projectId' element={<MainLayout><PdfPreAssessment /></MainLayout>} />
      <Route path='/project-summary/:projectId' element={<PreAssessmentLayout><ProjectSummary /></PreAssessmentLayout>} />
      <Route path='/pdf/project-summary/:projectId' element={<MainLayout><PdfSummary /></MainLayout>} />
    </Routes>
  </Router>
);

export default App;
