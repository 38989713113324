import { useMutation } from '@tanstack/react-query';
import { inviteNewUser } from 'shared/api/sharedApi';
import { InviteNewUserRequest } from '../interfaces/inviteNewUserRequest';

const useInviteNewUser = () => {
  const {
    mutate: inviteNewUserMutation,
    mutateAsync: inviteNewUserMutationAsync,
    isError: isErrorInviteNewUser,
  } = useMutation({
    mutationFn: async (model: InviteNewUserRequest) => await inviteNewUser(model)
  });
  return {
    inviteNewUserMutation,
    inviteNewUserMutationAsync,
    isErrorInviteNewUser,
  };
}

export default useInviteNewUser;
