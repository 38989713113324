export default function IconBookOpen() {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.9998 22.5L14.9165 22.3749C14.3376 21.5066 14.0482 21.0725 13.6658 20.7582C13.3272 20.4799 12.9371 20.2712 12.5178 20.1438C12.0442 20 11.5224 20 10.4789 20H9.33317C8.39975 20 7.93304 20 7.57652 19.8183C7.26292 19.6586 7.00795 19.4036 6.84816 19.09C6.6665 18.7335 6.6665 18.2668 6.6665 17.3333V10.1667C6.6665 9.23325 6.6665 8.76654 6.84816 8.41002C7.00795 8.09641 7.26292 7.84144 7.57652 7.68166C7.93304 7.5 8.39975 7.5 9.33317 7.5H9.6665C11.5333 7.5 12.4668 7.5 13.1798 7.86331C13.807 8.18289 14.3169 8.69282 14.6365 9.32003C14.9998 10.0331 14.9998 10.9665 14.9998 12.8333M14.9998 22.5V12.8333M14.9998 22.5L15.0832 22.3749C15.6621 21.5066 15.9515 21.0725 16.3339 20.7582C16.6725 20.4799 17.0625 20.2712 17.4818 20.1438C17.9555 20 18.4772 20 19.5208 20H20.6665C21.5999 20 22.0666 20 22.4232 19.8183C22.7368 19.6586 22.9917 19.4036 23.1515 19.09C23.3332 18.7335 23.3332 18.2668 23.3332 17.3333V10.1667C23.3332 9.23325 23.3332 8.76654 23.1515 8.41002C22.9917 8.09641 22.7368 7.84144 22.4232 7.68166C22.0666 7.5 21.5999 7.5 20.6665 7.5H20.3332C18.4663 7.5 17.5329 7.5 16.8199 7.86331C16.1927 8.18289 15.6827 8.69282 15.3631 9.32003C14.9998 10.0331 14.9998 10.9665 14.9998 12.8333'
        stroke='#667085'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
