import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    titleXL: React.CSSProperties;
    titleL: React.CSSProperties;
    titleM: React.CSSProperties;
    titleS: React.CSSProperties;
    bodyM: React.CSSProperties;
    bodyS: React.CSSProperties;
    bodyXS: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    titleXL?: React.CSSProperties;
    titleL?: React.CSSProperties;
    titleM?: React.CSSProperties;
    titleS?: React.CSSProperties;
    bodyM?: React.CSSProperties;
    bodyS?: React.CSSProperties;
    bodyXS?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    titleXL: true;
    titleL: true;
    titleM: true;
    titleS: true;
    bodyM: true;
    bodyS: true;
    bodyXS: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2176EF'
    },
    secondary: {
      main: '#D0D5DD'
    }
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'SourceSansPro-Regular',
          fontWeight:'600',
          fontSize:'14px',
          lineHeight:'20px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'SourceSansPro-Regular'
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: 'SourceSansPro-Regular',
        variantMapping: {
          titleXL: 'h2',
          titleL: 'h3',
          titleM: 'h4',
          titleS: 'h5',
          bodyM: 'p',
          bodyS: 'p',
          bodyXS: 'p'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded .MuiAccordionSummary-root': {
            minHeight: '48px',
          },
          '&.Mui-expanded .MuiAccordionSummary-content': {
            margin: '0',
          },
          '&.MuiAccordion-root::before' : {
            display: 'none',
          },
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          ':last-child': {
            paddingBottom: '30px'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: '400',
          fontStyle: 'normal',
          lineHeight: '20px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'SourceSansPro-Regular'
        }
      }
    }
  },
  typography: {
    body1: {
      fontSize: '16px',
      fontFamily: 'SourceSansPro-Regular'
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '16px',
      fontFamily: 'SourceSansPro-Regular'
    },
    titleXL: {
      fontSize: '28px',
      fontWeight: 600,
      fontFamily: 'SourceSansPro-Regular'
    },
    titleL: {
      fontSize: '20px',
      fontWeight: 600,
      fontFamily: 'SourceSansPro-Regular'
    },
    titleM: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 'normal',
      fontFamily: 'SourceSansPro-Regular'
    },
    titleS: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '17.6px',
      fontFamily: 'SourceSansPro-Regular'
    },
    bodyM: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20.11px',
      fontFamily: 'SourceSansPro-Regular'
    },
    bodyS: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
      fontFamily: 'SourceSansPro-Regular'
    },
    bodyXS: {
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: 'SourceSansPro-Regular'
    }
  }
});

export default theme;
