import { Grid } from '@mui/material';

export const PublicLayout: React.FC<{ children: any }> = ({ children }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </Grid>
);
