import { useQuery } from '@tanstack/react-query';
import { getProjects } from 'shared/api/sharedApi';
import { ProjectResponse } from 'features/Dashboard/interfaces/project';

export const emptyProject = {
  id: '',
  name: '',
  breeamVersion: '',
  targetRating: '',
  buildingType: '',
  buildingStage: '',
  breeamSeAp: '',
  targetCo2: ''
};

const initData = { message: '', projects: [] };

const useGetProjects = () => {
  const {
    data = initData,
    refetch,
    isLoading,
    isSuccess,
    isError
  } = useQuery<ProjectResponse>({
    queryKey: ['getProjects'],
    queryFn: getProjects
  });
  return { data, isLoading, isSuccess, isError, refetch };
};

export default useGetProjects;
