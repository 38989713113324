import { Alert, Snackbar } from '@mui/material';

interface CustomSnackbarProps {
  openSnackbar: boolean;
  message: string;
  isSuccess?: boolean;
  handleClose?: () => void;
}

const CustomSnackbar = ({ openSnackbar, message, isSuccess = false, handleClose }: CustomSnackbarProps) => (
  <Snackbar
    open={openSnackbar && message.length > 0}
    autoHideDuration={6000}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    <Alert icon={false} severity={isSuccess ? 'success' : 'error'}>
      {message}
    </Alert>
  </Snackbar>
);

export default CustomSnackbar;
