import { Box, Dialog, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { userSettings } from 'shared/api/sharedApi';
import CustomSnackbar from 'shared/components/CustomSnackbar';
import useGetUserSettings from './hooks/useGetUserSettings';
import { UserSettingsRequest } from './interfaces/userSettingsRequest';

export const UserSettings: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const [errors, setErrors] = useState<{ field: string; message: string }[]>([]);
  const firstNameError = errors.find((error) => error.field === 'FirstName')?.message;
  const lastNameError = errors.find((error) => error.field === 'LastName')?.message;
  const companyError = errors.find((error) => error.field === 'Company')?.message;
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const { t } = useTranslation();

  const { userSettingsData } = useGetUserSettings();

  const formik = useFormik({
    initialValues: {
      firstName: userSettingsData.firstName,
      lastName: userSettingsData.lastName,
      company: userSettingsData.company
    } as UserSettingsRequest,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const result = await userSettings({
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company
        });

        const fullName = `${values.firstName} ${values.lastName}`;
        var userData = JSON.parse(localStorage.getItem('userData') || '');
        userData.FullName = fullName;
        localStorage.setItem('userData', JSON.stringify(userData));

        setSnackBarMessage(result.message);

        setTimeout(() => {
          onClose();
        }, 3000);
      } catch (error: any) {
        setErrors(error.errors);
        setIsLoading(false);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('firstName', userSettingsData.firstName);
    formik.setFieldValue('lastName', userSettingsData.lastName);
    formik.setFieldValue('company', userSettingsData.company);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsData]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth PaperProps={{ sx: { borderRadius: '12px' } }}>
      <CustomSnackbar openSnackbar={!!snackBarMessage} message={snackBarMessage} isSuccess />
      <Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between' px={3} paddingTop='24px' paddingBottom={2}>
          <Typography sx={{ fontSize: '24px', fontStyle: 'normal', fontWeight: '600' }}>{t('Project.UserSettings.Settings')}</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box px={'24px'}>
        <Divider variant='fullWidth' />
      </Box>
      {/* <DialogContent> */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container gap={3} direction={'column'}>
          <Box padding='24px'>
            <Stack spacing={4}>
              <Box display='flex' gap={2} justifyContent='space-between'>
                <TextField
                  fullWidth
                  size='small'
                  id='firstName'
                  name='firstName'
                  label={t('Project.UserSettings.FirstName')}
                  value={formik.values.firstName ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(firstNameError)}
                  helperText={firstNameError}
                />
                <TextField
                  fullWidth
                  size='small'
                  id='lastName'
                  name='lastName'
                  label={t('Project.UserSettings.LastName')}
                  value={formik.values.lastName ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(lastNameError)}
                  helperText={lastNameError}
                />
              </Box>
              <TextField
                fullWidth
                size='small'
                id='email'
                name='email'
                label={t('Project.UserSettings.Email')}
                value={userSettingsData.email}
                disabled
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField
                fullWidth
                size='small'
                id='company'
                name='company'
                label={t('Project.UserSettings.CompanyName')}
                value={formik.values.company ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(companyError)}
                helperText={companyError}
              />
              <TextField
                fullWidth
                size='small'
                id='role'
                name='role'
                label={t('Project.UserSettings.Role')}
                value={userSettingsData.role}
                disabled
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {/* <Box display='flex' flexDirection='row' justifyContent='center' gap={2} px={3} sx={{ paddingBottom: 4 }}> */}
              <LoadingButton
                loading={isLoading}
                type='submit'
                variant='contained'
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '24px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingRight: '16px',
                  paddingLeft: '16px',
                  color: '#2176E',
                  borderRadius:'8px',
                  boxShadow: 'none'
                }}
              >
                {t('Project.UserSettings.Save')}
              </LoadingButton>
            </Stack>
          </Box>
          {/* </Box> */}
        </Grid>
      </form>
      {/* </DialogContent> */}
    </Dialog>
  );
};
