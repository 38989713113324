const Logo = () => (
  <svg width='23' height='26' viewBox='0 0 23 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.57028 6.22324C0.434216 11.4465 0.434202 16.6697 4.02947 21.8929V16.1474L8.44633 12.7785M7.62465 16.1474V23.9822C10.2374 25.194 11.7014 25.3265 14.3016 24.3647V8.31253L8.44633 12.7785M8.44633 12.7785V5.17859L16.8696 1V6.22324M16.8696 6.22324C23.5464 11.4465 22.5192 19.2813 16.8696 23.4599V6.22324Z'
      stroke='#232324'
      strokeWidth='1.2'
    />
  </svg>
);

export default Logo;
