import React from 'react';
import { Box } from '@mui/material';
import { Header } from './Header';

import { SideBar } from './SideBar';

export const PreAssessmentLayout: React.FC<{ children: any }> = ({ children }) => {
  return (
    <>
      <Header />
      <Box component='main' bgcolor={'#F9FAFB'} sx={{ flexGrow: 1, mt: '32px', paddingTop: '30px', paddingLeft:'50px'}} style={{minHeight: 'calc(100vh - 65px)'}}>
       <SideBar unsavedChanges={false} isCreateProjectPage={false} idProject={'projectId'}  isAfterSave={false} />
        {children}
      </Box>
    </>
  );
};
