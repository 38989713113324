import React from 'react';
import { Box } from '@mui/material';
import { Header } from './Header';

import { SideBar } from './SideBar';

export const MainLayout: React.FC<{ children: any }> = ({ children }) => {
  return (
    <>
      <Header />
      <SideBar  unsavedChanges={false} isCreateProjectPage={false} idProject={'projectId'} isAfterSave={true}/>
      <Box component='main' bgcolor={'#F9FAFB'} sx={{ flexGrow: 1, mt: '65px', ml: '50px', p: '50px'}} style={{minHeight: 'calc(100vh - 65px)'}}>
        {children}
      </Box>
    </>
  );
};
