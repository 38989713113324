export default function HomeIcon({ active }: { active: boolean }) {
  const strokeColor = active ? '#2176EF' : '#667085';
  const bgColor = active ? '#EFF8FF' : '';


  return (
    <svg width='49' height='60' viewBox='0 0 49 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='49' height='60' fill='white' />
      <path
        d='M10 21C10 17.6863 12.6863 15 16 15H34C37.3137 15 40 17.6863 40 21V39C40 42.3137 37.3137 45 34 45H16C12.6863 45 10 42.3137 10 39V21Z'
        fill={bgColor}
      />
      <path
        d='M25.8186 22.3033C25.5258 22.0756 25.3794 21.9618 25.2178 21.918C25.0752 21.8794 24.9248 21.8794 24.7822 21.918C24.6206 21.9618 24.4742 22.0756 24.1814 22.3033L18.5295 26.6993C18.1517 26.9931 17.9628 27.14 17.8267 27.324C17.7061 27.487 17.6163 27.6707 17.5617 27.8659C17.5 28.0863 17.5 28.3256 17.5 28.8042V34.8333C17.5 35.7667 17.5 36.2335 17.6817 36.59C17.8414 36.9036 18.0964 37.1585 18.41 37.3183C18.7665 37.5 19.2332 37.5 20.1667 37.5H21.8333C22.0667 37.5 22.1834 37.5 22.2725 37.4546C22.3509 37.4146 22.4146 37.3509 22.4546 37.2725C22.5 37.1834 22.5 37.0667 22.5 36.8333V31.3333C22.5 30.8666 22.5 30.6333 22.5908 30.455C22.6707 30.2982 22.7982 30.1707 22.955 30.0908C23.1333 30 23.3666 30 23.8333 30H26.1667C26.6334 30 26.8667 30 27.045 30.0908C27.2018 30.1707 27.3293 30.2982 27.4092 30.455C27.5 30.6333 27.5 30.8666 27.5 31.3333V36.8333C27.5 37.0667 27.5 37.1834 27.5454 37.2725C27.5854 37.3509 27.6491 37.4146 27.7275 37.4546C27.8166 37.5 27.9333 37.5 28.1667 37.5H29.8333C30.7668 37.5 31.2335 37.5 31.59 37.3183C31.9036 37.1585 32.1586 36.9036 32.3183 36.59C32.5 36.2335 32.5 35.7667 32.5 34.8333V28.8042C32.5 28.3256 32.5 28.0863 32.4383 27.8659C32.3837 27.6707 32.2939 27.487 32.1733 27.324C32.0372 27.14 31.8483 26.9931 31.4705 26.6993L25.8186 22.3033Z'
        stroke={strokeColor}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line x1='10' y1='59.5' x2='40' y2='59.5' stroke='#EAECF0' />
    </svg>
  );
}

