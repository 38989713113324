import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UpdatingInfoIcon from './Icons/UpdatingInfoIcon';

interface DialogEditWarningProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  projectId: string;
}

export default function DialogEditWarning({ open, setOpen, projectId }: DialogEditWarningProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEditForm = () => {
    navigate(`/project/${projectId}`);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' PaperProps={{ sx: { borderRadius: '12px' } }}>
      <Box display='flex' justifyContent='space-between' color='red' paddingTop={3} px={3}>
        <Box display='flex' alignItems='center'>
          <UpdatingInfoIcon />
        </Box>
        <Box paddingTop={1}>
          <IconButton onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box zIndex={1}>
        <DialogTitle fontWeight='600' fontSize='20px' lineHeight='30px' fontStyle='normal'>
          {t('Project.CreateProject.UpdatingInformation')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText fontWeight='400' fontSize='14px' lineHeight='20px' fontStyle='normal'>
            {t('Project.CreateProject.IfYouEdit')}
          </DialogContentText>
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          paddingLeft: '24px',
          paddingRight: '24px',
          paddingBottom: '24px',
          paddingTop: '12px',
          gap: '12px'
        }}
      >
        <Button
          onClick={handleClose}
          variant='outlined'
          fullWidth
          sx={{
            borderRadius: '8px',
            borderColor: '#D0D5DD',
            color: '#344054',
            textTransform: 'none',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '20px',
            py: '10px',
            px: '16px',
            boxShadow: 'none'
          }}
        >
          {t('Project.InviteNewUser.Cancel')}
        </Button>
        <LoadingButton
          variant='contained'
          fullWidth
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '24px',
            py: '10px',
            px: '16px',
            boxShadow: 'none',
            borderRadius: '8px'
          }}
          onClick={handleOpenEditForm}
        >
          {t('Project.ProjectCard.Edit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
