import axios from 'shared/api/axiosConfig';

interface IError {
  response: {
    data: {
      errors: {
        filed: string;
        message: string;
        attemptedValue: string;
      }[];
    };
  };
}

const post = async (path: string, requestData: any, contentType: string = 'application/json') => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post(path, requestData, {
      headers: {
        'Content-Type': contentType,
        ...(token ? { Authorization: `Bearer ${token}` } : {})
      }
    });

    if (response.status === 200) {
      return Promise.resolve(response.data ?? []);
    } else {
      return Promise.reject(response?.data);
    }
  } catch (error: any) {
    const errorData: IError = error;
    return Promise.reject(errorData?.response?.data);
  }
};

const get = async (path: string) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(path, {
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {})
      }
    });

    return response?.data ?? [];
  } catch (error: any) {
    const errorData: IError = error;
    return Promise.reject(errorData?.response?.data);
  }
};

const put = async (path: string, requestData: any) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put(path, requestData, {
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {})
      }
    });

    if (response.status === 200) {
      return Promise.resolve(response.data ?? []);
    } else {
      return Promise.reject(response?.data);
    }
  } catch (error: any) {
    const errorData: IError = error;
    return Promise.reject(errorData?.response?.data);
  }
};

export { post, get, put };
