import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, DialogContentText , IconButton} from '@mui/material';
import ProjectWarningIcon from './ProjectWarningIcon';
import { CloseOutlined } from '@mui/icons-material';


interface Props {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
}

const UnsavedChangesModal: React.FC<Props> = ({ open, onClose, onLeave }) => {
  return (
    <Dialog
      open={open}
      maxWidth='xs'
      PaperProps={{ sx: { borderRadius: '12px' } }}
      >
      <Box display='flex' justifyContent='space-between' color='red' paddingTop={3} px={3}>
       <Box display='flex' alignItems='center'>
       <ProjectWarningIcon />
      </Box>
      <Box paddingTop={1}>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
      </Box>

      <Box zIndex={1}>
          <DialogTitle sx={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px', fontStyle: 'normal', color: '#101828' }}>
          Dina svar är inte sparade
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', fontStyle: 'normal' }}>
            Är du säker på att du vill lämna utan att spara?
            </DialogContentText>
          </DialogContent>
        </Box>
      <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  paddingLeft: '24px',
                  paddingRight: '24px',
                  paddingBottom: '24px',
                  paddingTop: '12px',
                  gap: '12px'
                }}
              >
                <Button
                  onClick={onClose}
                  variant='outlined'
                  fullWidth
                  sx={{
                     borderRadius: '8px',
                     borderColor: '#D0D5DD',
                     color: '#344054',
                     textTransform: 'none',
                     fontSize: '16px',
                     fontStyle: 'normal',
                     fontWeight: '600',
                     lineHeight: '20px',
                     py: '10px',
                     px: '16px',
                     boxShadow: 'none'
                   }}
                 >
               Avbryt
                </Button>

                <Button
                  onClick={onLeave}
                  variant='contained'
                  fullWidth
                   sx={{
                     fontSize: '16px',
                     fontStyle: 'normal',
                     color: '#ffffff',
                     bgcolor: '#D62C23',
                     fontWeight: '600',
                     lineHeight: '24px',
                     py: '10px',
                     px: '16px',
                     boxShadow: 'none'
                   }}
                   style={{
                     boxShadow: 'none',
                     borderRadius: '8px'
                   }}

                >
                 Lämna
                </Button>
              </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesModal;
