
import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/Logo';
import HomeIcon from 'assets/Icons/HomeIcon';
import UnsavedChangesModal from 'features/CreateProject/components/UnsavedChangesModal';

interface SideBarProps {
  unsavedChanges: boolean;
  isCreateProjectPage: boolean;
  idProject: string | undefined;
  isAfterSave: boolean
}

export const SideBar: React.FC<SideBarProps> = ({ unsavedChanges, isCreateProjectPage, idProject, isAfterSave }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const StyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
      width: 50,
      borderTopWidth: 0,
      borderRightWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 0
    }
  });

  const isHome = location.pathname === '/home';
  const handleNavigateToHome = () => {

    if ((isCreateProjectPage && unsavedChanges  && idProject === '') || isAfterSave)
    {
        setIsModalOpen(true);
    } else {
      navigate('/home');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLeave = () => {
    setIsModalOpen(false);
    navigate('/home');
  };

  return (
    <>
      <StyledDrawer variant='permanent'>
        <Box display='flex' paddingTop='20px' px='12px' paddingBottom='10px'>
          <Logo />
        </Box>
        <List>
          <ListItem key={'Home'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={handleNavigateToHome}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                padding: 0
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 'auto',
                  justifyContent: 'center'
                }}
              >
                <HomeIcon active={isHome} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </StyledDrawer>
      {isCreateProjectPage && (
        <UnsavedChangesModal open={isModalOpen} onClose={handleCloseModal} onLeave={handleLeave} />
      )}
    </>
  );
};
