import { useQuery } from '@tanstack/react-query';
import { getUserSettings } from 'shared/api/sharedApi';
import { UserSettingsResponse } from '../interfaces/userSettingsResponse';

const initData = { message: '', firstName: '', lastName: '', company: '', role: '', email: '' }

const useGetUserSettings = () => {
  const {
    data = initData,
    refetch,
    isLoading,
    isError
  } = useQuery<UserSettingsResponse>({
    queryKey: ['getUserSettings'],
    queryFn: getUserSettings
  });
  return { userSettingsData: data, isLoading, isError, refetch };
};

export default useGetUserSettings;
